import React from "react"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

import busEntry from "./images/bus-entry.jpg"
import carPark from "./images/car-park.jpg"
import charger from "./images/charger.jpg"
import driverRoom from "./images/driver-room.jpg"
import hubSupportStorage from "./images/hub-support-storage.jpg"
import motorbikeParking from "./images/motorbike-parking.jpg"
import officeDoor from "./images/office-door.jpg"
import officeLocation from "./images/office-location.png"
import office from "./images/office.jpg"
import smokingShelter from "./images/smoking-shelter.jpg"
import turnstiles from "./images/turnstiles.jpg"

const Page = () => {
  return (
    <DriveLayout title="Michelin Scotland Innovation Parc">
      <Hero title="Michelin Scotland Innovation Parc" />
      <Container size="medium">
        <Stack spacing="XLarge">
          <EmberCard title="Introduction">
            <EmberCardSection>
              <Stack>
                <Text>
                  Our site at Michelin Scotland Innovation Parc (MSIP) contains
                  charging, office and rest facilities. It's currently the main
                  charging and driver hub for Glasgow services and is the base
                  for Hub Support.
                </Text>
                <Text>
                  You must complete the MSIP site induction in order to be on
                  site alone. If you haven't done the induction, you'll need to
                  be escorted on site at all times. In addition, you should be
                  set up on the Verkada pass app so you can sign in
                  automatically otherwise you'll need to go to the gatehouse.
                  Contact the Operations Team if you need to help with either of
                  these things.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Staff Parking">
            <EmberCardSection>
              <Stack>
                <Text>
                  When arriving by car, you should park in the car park on the
                  left hand side before the zebra crossing and the gatehouse.
                </Text>
                <img src={carPark} />
                <Text>
                  For those with motorbikes or mopeds there is temporary parking
                  beyond security, on the way to our area. This bay has space
                  for up to four bikes. You will need to scan in with security
                  to gain access to motorbike parking.
                </Text>
                <img src={motorbikeParking} />
                <Text>
                  If you are on a bike, you can leave it on the bike racks just
                  to the right past security. You'll need to ask security to let
                  you through the bike gate.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Entering the Site">
            <EmberCardSection>
              <Stack>
                <Text>
                  To enter the site on foot, you can scan through the turnstiles
                  using the Verkada app on your phone. Make sure Bluetooth is
                  enabled on your phone and the app is showing as broadcasting,
                  then press your phone to the black pad on the right. Once it
                  beeps and goes green you can enter.
                </Text>
                <img src={turnstiles} />
                <Text>
                  If entering the site by bus, pull up to the security barrier
                  and use the app to register your presence for security by
                  clicking the padlock for the gate on the app. Alternatively
                  tap your phone on the access pad on the bollard. In either
                  case, this will alert security that someone is waiting for
                  access and they will open the barrier. There may be times when
                  you pull up to the gate and security are not present, on
                  patrol or dealing with an issue. If this happens please call
                  07880273967 and ask them to return to the gatehouse quickly to
                  lift the barrier.
                </Text>
                <img src={busEntry} />
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Location of Ember's Facilities">
            <EmberCardSection>
              <Stack>
                <Text>
                  Our office is located in the Cavendish Building (building 26).
                  To get to our building, turn left at the crossroads after
                  gatehouse and follow the road. The office is on the right,
                  just after the lay-by. The charger is just beyond. The route
                  is marked by a red line on the site map below.
                </Text>
                <Text>
                  If going by foot, use all designated walkways and do not
                  wander elsewhere on site. If driving, there is a strict 15 mph
                  speed limit in place for vehicles on site (with no private
                  cars allowed). The road is a single lane in places so look
                  ahead and follow all road safety signs (e.g. using your horn
                  to alert oncoming vehicles).
                </Text>
                <img src={officeLocation} />
                <Text>
                  The door to the office is shown below. Three keys are required
                  to enter our area within the building (the two outer doors
                  then the door to the right).
                </Text>
                <img src={officeDoor} />
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Driver Facilities">
            <EmberCardSection>
              <Stack>
                <Text>
                  Within the building we have a driver room/kitchen, a storage
                  room, an office, a toilet and a cleaning cupboard.
                </Text>
                <Text>
                  The toilet is located to the right just after you enter our
                  area of the building.
                </Text>
                <Text>
                  The driver room with kitchen facilities is located at the end
                  of the hallway. This has a kettle, microwave, fridge, dining
                  table and sofa. The first aid kit is located under the sink.
                </Text>
                <img src={driverRoom} />
                <Text>
                  The first door on the right after the toilet is an office used
                  by the Operations and Maintenance team.
                </Text>
                <img src={office} />
                <Text>
                  The next door on the right is used by the Hub Support Team for
                  storage of cleaning materials, lost property and spare parts.
                </Text>
                <img src={hubSupportStorage} />
                <Text>
                  Smoking is only permitted on site in the designated shelter
                  near the front door.
                </Text>
                <img src={smokingShelter} />
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Charger">
            <EmberCardSection>
              <Stack>
                <Text>
                  We have charging facilities for buses at the rear of the
                  building. If no buses are on charge when arriving, use the
                  open space as a turning circle and drive the bus, at low speed
                  forwards into Bay 1 to charge. If there is already a bus on
                  charge, you will need to reverse into Bay 2. There are bumps
                  in place to help with getting the bus in the right position.
                </Text>
                <img src={charger} />
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Fire Safety">
            <EmberCardSection>
              <Stack>
                <Text>
                  There are multiple fire extinguishers, both foam and CO2
                  throughout the corridors. There is a fire blanket on the wall
                  in the kitchen.
                </Text>
                <Text>
                  The main fire escape route is through the corridor and out the
                  front door. The secondary escape route is through the fire
                  door in the kitchen area and out the nearest fire door on the
                  right in the warehouse space. The muster point in an
                  evacuation is at the lay-by across from the building. The fire
                  alarm will be tested every Monday at 12:00.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
        </Stack>
      </Container>
    </DriveLayout>
  )
}

export default Page
